var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root-layout" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("error.title", { code: _vm.code })))]),
      _c("h3", [_vm._v(_vm._s(_vm.$t("error.messages." + _vm.code)))]),
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "Dashboard" })
            }
          }
        },
        [
          _c("i", { staticClass: "fa fa-arrow-circle-left" }),
          _vm._v(" " + _vm._s(_vm.$t("error-page.turn-back")))
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }