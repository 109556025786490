<template>
  <div class="root-layout">
    <h1>{{ $t('error.title', {code: code}) }}</h1>
    <h3>{{ $t("error.messages." + code) }}</h3>
    <el-button type="text" @click="$router.push({name: 'Dashboard'})"><i class="fa fa-arrow-circle-left"></i> {{ $t('error-page.turn-back') }}</el-button>
  </div>
</template>

<script>
  export default {
    name: "NotFound",
    data: function () {
      return {
        code: 404
      }
    }
  }
</script>


<style scoped>
  .root-layout {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
</style>